import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../components/layouts/signup'
import { Signup } from '../components/login/signup'

export default function SignupPage(props: PageProps) {
  return (
    <Layout>
      <Signup />
    </Layout>
  )
}
