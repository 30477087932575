import React, { useState, useEffect, useMemo, useCallback } from 'react'
import SVG from 'react-inlinesvg'
import {
  SignupIllustration,
  Link,
  useModal,
  useSnackbar,
  navigateTo,
  varClass,
  host,
  copyToClipboard,
  capitalize,
  isBlank,
  saveValue,
  loadObjectValue,
  removeValue,
  signupStateStorageKey,
  responseError,
} from '../shared'
import { useAuth } from '../shared/hooks'
import {
  getRequest,
  postRequest,
  scriptmanagerPost,
  login,
} from '../../services'

const scriptHost = process.env.SCRIPT_HOST
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const domainRegex = /^(https?:\/\/)?[^\s./]+(\.[^\s./]+)+$/

const focus = (selector: string) => {
  const element = document.querySelector(selector) as HTMLInputElement
  if (element) {
    element.focus()
  }
}

const channelDomainToName = (domain: string): string =>
  capitalize(host(domain).replace('www.', '').split('.')[0].toLowerCase())

const checkChannelClicks = (data, channelUuid): boolean => {
  if (isBlank(data)) return false

  const channel = data.find((item) => item.channel.channelUuid === channelUuid)
  if (!channel) return false

  const stats = [
    ...Object.values(channel.discover),
    ...Object.values(channel.statTotals),
    ...Object.values(channel.clicksInsight),
  ]
  return stats.some((stat) => typeof stat === 'number' && stat > 0)
}

export const Signup = (props) => {
  const authenticated = useAuth({})
  const initialState = useMemo(() => loadObjectValue(signupStateStorageKey), [])

  // Hard disable signup
  useEffect(() => {
    navigateTo('/')
  }, [])

  useEffect(() => {
    if (authenticated === true && isBlank(initialState)) {
      navigateTo('/')
    }
  }, [authenticated])

  const [step, setStep] = useState(() => initialState.step || 1)
  const gotoNextStep = () => setStep(step + 1)
  const gotoPrevStep = () => setStep(step - 1)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [channelDomain, setChannelDomain] = useState(
    () => initialState.channelDomain || ''
  )
  const [accepted, setAccepted] = useState(false)
  const [connectType, setConnectType] = useState('')
  const [connectVerified, setConnectVerified] = useState(false)

  const [channelUuid, setChannelUuid] = useState(
    () => initialState.channelUuid || ''
  )
  const [submitted, setSubmitted] = useState(() => !isBlank(initialState))

  const stepProps = {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    userEmail,
    setUserEmail,
    companyName,
    setCompanyName,
    password,
    setPassword,
    passwordRepeat,
    setPasswordRepeat,
    channelDomain,
    setChannelDomain,
    accepted,
    setAccepted,
    submitted,
    setSubmitted,
    channelUuid,
    setChannelUuid,
    connectType,
    setConnectType,
    connectVerified,
    setConnectVerified,
    step,
    setStep,
    gotoNextStep,
    gotoPrevStep,
  }

  return null

  return (
    <>
      <div className="signup-content">
        <div>
          <SVG
            src="/images/logo-dark.svg"
            width={126}
            height={32}
            className="m-l-50"
          />
          <form
            id="signup-form"
            className={varClass({
              'signup-form': true,
              'signup-form-step-1': step === 1,
              'signup-form-step-2': step === 2,
              'signup-form-step-3': step === 3,
              'signup-form-step-4': step === 4,
              'signup-form-step-5': step === 5,
            })}
            onSubmit={(event) => {
              event.preventDefault()
            }}
          >
            <Step1 tabIndex={step === 1 ? 0 : -1} {...stepProps} />
            <Step2 tabIndex={step === 2 ? 0 : -1} {...stepProps} />
            <Step3 tabIndex={step === 3 ? 0 : -1} {...stepProps} />
            <Step4 tabIndex={step === 4 ? 0 : -1} {...stepProps} />
            <Step5 tabIndex={step === 5 ? 0 : -1} {...stepProps} />
          </form>
        </div>
        {/*
        <div className="text-light">
          ©{new Date().getFullYear()} Heylink™. All rights reserved.
        </div>
        */}
      </div>
      <SignupIllustration />
    </>
  )
}

const Step1 = (props) => {
  const {
    tabIndex,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    userEmail,
    setUserEmail,
    companyName,
    setCompanyName,
    password,
    setPassword,
    passwordRepeat,
    setPasswordRepeat,
    accepted,
    setAccepted,
    gotoNextStep,
  } = props

  const [error, setError] = useState('')

  const submit = async () => {
    setError('')

    if (!firstName) {
      focus("input[name='firstName']")
      setError('First name is required')
      return
    }
    if (firstName.length < 2) {
      focus("input[name='firstName']")
      setError('First name length must be at least 2 characters long')
      return
    }
    if (!lastName) {
      focus("input[name='lastName']")
      setError('Last name is required')
      return
    }
    if (lastName.length < 2) {
      focus("input[name='lastName']")
      setError('Last name length must be at least 2 characters long')
      return
    }
    if (!userEmail) {
      focus("input[name='userEmail']")
      setError('Email is required')
      return
    }
    if (!emailRegex.test(userEmail)) {
      focus("input[name='userEmail']")
      setError('Email is invalid')
      return
    }
    if (!password) {
      focus("input[name='password']")
      setError('Password is required')
      return
    }
    if (password.length < 8) {
      focus("input[name='password']")
      setError('Password length must be at least 8 characters long')
      return
    }
    if (passwordRepeat !== password) {
      focus("input[name='passwordRepeat']")
      setError('Password should match')
      return
    }

    gotoNextStep()
  }

  const { showModal } = useModal()

  return (
    <div className="step">
      <div className="step-content">
        <div className="h1 m-b-10">Hi, welcome to Heylink!</div>
        <div className="text-light text-bigger text-bold m-b-40">
          Create an account. Connect a network.
          <br />
          Change your affiliate game forever.
        </div>

        {/*<button className="btn w-100 p-y-4 vertical-middle">
        <SVG src="/images/logo-google.svg" className="m-r-3" />
        <span className="text-dark text-big text-bolder">
          Sign up with Google
        </span>
      </button>
      <div className="login-email-divider m-y-8">
        <span>or Sign up with mail</span>
      </div>*/}

        <div>
          <div className="row row-space-between m-b-20">
            <div className="control flex-1-2">
              <label>First name</label>
              <input
                id="userFirstname"
                type="text"
                name="firstName"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                tabIndex={tabIndex}
              />
            </div>
            <div className="control flex-1-2">
              <label>Last name</label>
              <input
                id="userLastname"
                type="text"
                name="lastName"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                tabIndex={tabIndex}
              />
            </div>
          </div>

          <div className="control m-b-20">
            <label>Email</label>
            <input
              id="userEmail"
              type="email"
              name="userEmail"
              autoComplete="off"
              value={userEmail}
              onChange={(event) => setUserEmail(event.target.value)}
              tabIndex={tabIndex}
            />
          </div>

          <div className="control m-b-20">
            <label>Company name</label>
            <input
              id="companyName"
              type="text"
              name="companyName"
              autoComplete="off"
              value={companyName}
              onChange={(event) => setCompanyName(event.target.value)}
              tabIndex={tabIndex}
            />
          </div>

          <div className="row row-space-between m-b-20">
            <div className="control flex-1-2">
              <label>Password</label>
              <input
                id="userPassword"
                type="password"
                name="password"
                autoComplete="new-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                tabIndex={tabIndex}
              />
            </div>
            <div className="control flex-1-2">
              <label>Repeat password</label>
              <input
                id="userPasswordRepeat"
                type="password"
                name="passwordRepeat"
                value={passwordRepeat}
                onChange={(event) => setPasswordRepeat(event.target.value)}
                tabIndex={tabIndex}
              />
            </div>
          </div>

          <div className="control control-checkbox">
            <input
              id="termsAccept"
              type="checkbox"
              checked={accepted}
              onChange={(event) => setAccepted(event.target.checked)}
              tabIndex={tabIndex}
            />
            <label>
              I have read and accept Heylink’s{' '}
              <span
                className="link cursor-pointer"
                onClick={() => {
                  showModal(<TermsModal />)
                }}
              >
                Terms and Conditions
              </span>
            </label>
          </div>

          <button
            className="signup-create vertical-middle btn btn-primary m-t-60"
            type="submit"
            onClick={submit}
            disabled={!accepted}
            tabIndex={tabIndex}
          >
            Create your FREE account
          </button>
        </div>

        {error && (
          <div
            className="signup-verify-error text-center text-red text-bolder m-t-4"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        )}

        <div className="text-light text-big text-bold m-t-20">
          Already have an account?{' '}
          <Link href="/login/" className="link">
            Login here
          </Link>
        </div>
      </div>
    </div>
  )
}

const Step2 = (props) => {
  const {
    tabIndex,
    firstName,
    lastName,
    userEmail,
    companyName,
    password,
    passwordRepeat,
    channelDomain,
    setChannelDomain,
    accepted,
    setChannelUuid,
    submitted,
    setSubmitted,
    setStep,
    gotoPrevStep,
    gotoNextStep,
  } = props

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    setError('')
  }, [tabIndex])

  const submit = async () => {
    setError('')

    if (!firstName) {
      setError('First name is required')
      return
    }
    if (!lastName) {
      setError('Last name is required')
      return
    }
    if (!userEmail || !emailRegex.test(userEmail)) {
      if (!userEmail) {
        setError('Email is required')
      } else if (!emailRegex.test(userEmail)) {
        setError('Email is invalid')
      }
      return
    }
    if (!password) {
      setError('Password is required')
      return
    }
    if (passwordRepeat !== password) {
      setError('Password should match')
      return
    }
    if (!channelDomain || !domainRegex.test(channelDomain)) {
      focus("input[name='channelDomain']")
      if (!channelDomain) {
        setError('Site domain is required')
      } else if (!domainRegex.test(channelDomain)) {
        setError('Site domain is invalid')
      }
      return
    }

    if (submitted) {
      // TODO: Update previous submission (?)
      gotoNextStep()
      return
    }

    const channelName = channelDomainToName(channelDomain)

    setSubmitting(true)
    const response = await postRequest('/signup/v1/flow', {
      userFirstname: firstName,
      userLastname: lastName,
      userEmail: userEmail,
      userPassword: password,
      userPasswordRepeat: passwordRepeat,
      accountName: companyName,
      channelName: channelName,
      channelDomain: channelDomain,
    })
    setSubmitting(false)

    if (response && response.code === 200) {
      setSubmitted(true)
      setChannelUuid(response.data.channelUuid)
      login(response.data.jwt)
      gotoNextStep()
    } else {
      setError(responseError(response))
    }
  }

  // const skip = async () => {
  //   setError('')

  //   if (!firstName) {
  //     setError('First name is required')
  //     return
  //   }
  //   if (!lastName) {
  //     setError('Last name is required')
  //     return
  //   }
  //   if (!userEmail || !emailRegex.test(userEmail)) {
  //     if (!userEmail) {
  //       setError('Email is required')
  //     } else if (!emailRegex.test(userEmail)) {
  //       setError('Email is invalid')
  //     }
  //     return
  //   }
  //   if (!password) {
  //     setError('Password is required')
  //     return
  //   }
  //   if (passwordRepeat !== password) {
  //     setError('Password should match')
  //     return
  //   }

  //   if (submitted) {
  //     setStep(5)
  //     return
  //   }

  //   setSubmitting(true)
  //   const response = await postRequest('/signup/v1/account', {
  //     userFirstname: firstName,
  //     userLastname: lastName,
  //     userEmail: userEmail,
  //     userPassword: password,
  //     userPasswordRepeat: passwordRepeat,
  //   })
  //   setSubmitting(false)

  //   if (response && response.code === 200) {
  //     setSubmitted(true)
  //     login(response.data.jwt)
  //     setStep(5)
  //   } else {
  //     setError(responseError(response))
  //   }
  // }

  return (
    <div className="step">
      <div className="step-content">
        <div className="m-b-30">
          <button
            onClick={gotoPrevStep}
            className="link vertical-middle"
            tabIndex={tabIndex}
          >
            <SVG src="/images/arrow-back.svg" />
            <span className="text-light text-big text-bold m-l-3">Go back</span>
          </button>
        </div>
        <div className="h1 m-b-10">Add your first website</div>
        <div className="text-light text-bigger text-bold m-b-40">
          Add your website URL to finish the signup and create your account
        </div>

        {/*<button className="btn w-100 p-y-4 vertical-middle">
        <SVG src="/images/logo-google.svg" className="m-r-3" />
        <span className="text-dark text-big text-bolder">
          Sign up with Google
        </span>
      </button>
      <div className="login-email-divider m-y-8">
        <span>or Sign up with mail</span>
      </div>*/}

        <div>
          <div className="text-dark text-big text-bolder m-t-40 m-b-4"></div>

          <div className="control m-b-30">
            <label>Insert URL</label>
            <input
              id="channelName"
              type="text"
              name="channelDomain"
              placeholder="ex. domain.com"
              value={channelDomain}
              onChange={(event) => setChannelDomain(event.target.value)}
              tabIndex={tabIndex}
            />
          </div>

          <button
            className="signup-create vertical-middle btn btn-primary m-t-60"
            type="submit"
            onClick={submit}
            disabled={!accepted || submitting}
            tabIndex={tabIndex}
          >
            {submitting ? (
              <>
                <span className="spinner" />
                Submitting...
              </>
            ) : (
              <>Let's go!</>
            )}
          </button>

          {/*
          <button
            className="signup-skip m-t-30"
            onClick={skip}
            disabled={!accepted || submitting}
            tabIndex={tabIndex}
          >
            Skip for now
          </button>
          */}
        </div>

        {error && (
          <div
            className="signup-verify-error text-center text-red text-bolder m-t-4"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        )}
      </div>
    </div>
  )
}

const Step3 = (props) => {
  const {
    tabIndex,
    channelDomain,
    channelUuid,
    connectType,
    setConnectType,
    step,
    setStep,
    gotoNextStep,
  } = props

  useEffect(() => {
    if (step === 3) {
      saveValue(
        signupStateStorageKey,
        JSON.stringify({
          step: 3,
          channelDomain,
          channelUuid,
        })
      )
    }
  }, [step, channelDomain, channelUuid])

  const submit = (value) => {
    setConnectType(value)
    gotoNextStep()
  }

  const optionProps = {
    connectType,
    submit,
  }

  return (
    <div className="step">
      <div className="step-content">
        {/*
        <div className="m-b-30">
          <button onClick={gotoPrevStep} className="link vertical-middle">
            <SVG src="/images/arrow-back.svg" />
            <span className="text-light text-big text-bold m-l-3">Go back</span>
          </button>
        </div>
        */}
        <div className="h1 m-b-10">Connect to Heylink</div>
        <div className="text-light text-bigger text-bold m-b-40">
          Choose the connection method best suited for{' '}
          <span className="text-primary">{host(channelDomain)}</span>.
        </div>
        <Step3Option
          value="script"
          label="Connect with Heylink Script"
          description="It works with any website and is very easy to install"
          tag="Most common"
          {...optionProps}
        />
        <Step3Option
          value="wordpress"
          label="Connect with the WordPress Plugin"
          description="Easy and automatic integration with WordPress using our brilliant plugin"
          {...optionProps}
        />
        {/*
        <Step3Option
          value="api"
          label="Connect with Custom API"
          description="If you need advanced functionality, our API might be the way to go"
          tag="Most technical"
          {...optionProps}
        />
        */}

        <button
          className="signup-skip"
          onClick={() => {
            setStep(5)
          }}
          tabIndex={tabIndex}
        >
          Skip for now
        </button>
      </div>
    </div>
  )
}

const Step3Option = (props) => {
  const { value, label, description, tag, connectType, submit } = props

  return (
    <div
      className={varClass({
        'signup-option': true,
        'active': connectType === value,
      })}
      onClick={() => {
        submit(value)
      }}
    >
      <div className="text-bold m-b-05">Connect with</div>
      <div className="text-dark text-big text-bolder m-b-3">{label}</div>
      <div className="inline text-light m-r-110">{description}</div>
      {tag && <div className="signup-option-tag">{tag}</div>}
    </div>
  )
}

const Step4 = (props) => {
  const {
    tabIndex,
    channelDomain,
    channelUuid,
    connectType,
    setConnectVerified,
    setStep,
    gotoPrevStep,
    gotoNextStep,
  } = props

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const submit = useCallback(async () => {
    if (submitting) return

    setSubmitting(true)
    setError('')

    let verified = false

    if (connectType === 'script') {
      if (!verified && channelDomain) {
        const scriptVerify = await scriptmanagerPost('/script/check', {
          domain: channelDomain,
        })
        if (
          scriptVerify &&
          scriptVerify.status === 'ok' &&
          scriptVerify.presence
        ) {
          verified = true
        }
      }

      if (!verified && channelUuid) {
        const channelStats = await getRequest('/channels/v1/stats')
        if (checkChannelClicks(channelStats?.data, channelUuid)) {
          verified = true
        }
      }
    } else if (connectType === 'wordpress') {
      if (!verified && channelDomain) {
        const pluginVerify = await window
          .fetch(`https://${host(channelDomain)}/wp-json/heylink/status`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((data) => data.json())
          .catch((error) => error)

        if (pluginVerify && pluginVerify.plugin_version) {
          verified = true
        }
      }

      if (!verified && channelUuid) {
        const channelStats = await getRequest('/channels/v1/stats')
        if (checkChannelClicks(channelStats?.data, channelUuid)) {
          verified = true
        }
      }
    } else if (connectType === 'api') {
      if (!verified && channelUuid) {
        const channelStats = await getRequest('/channels/v1/stats')
        if (checkChannelClicks(channelStats?.data, channelUuid)) {
          verified = true
        }
      }

      verified = true
    }

    setSubmitting(false)

    if (verified) {
      setConnectVerified(true)
      gotoNextStep()
    } else {
      setError(
        'Oh no! We couldn\'t verify your site. Please make sure to clear or disable cache and try again. If you still aren\'t able to verify, please <a href="mailto:support@heylink.com">get in touch with support</a>.'
      )
    }
  }, [])

  return (
    <div className="step">
      <div className="step-content">
        {/*
      <div className="m-b-30">
        <button
          onClick={() => {
            setStep(1)
          }}
          className="link vertical-middle"
        >
          <SVG src="/images/arrow-back.svg" />
          <span className="text-light text-big text-bold m-l-3">Go back</span>
        </button>
      </div>
      */}
        <div className="h1 m-b-10">Connect to Heylink</div>
        <div className="text-light text-bigger text-bold m-b-40">
          Choose the connection method best suited for{' '}
          <span className="text-primary">{host(channelDomain)}</span>.
        </div>

        {connectType === 'script' && (
          <div className="signup-guide">
            <div className="guide-header" onClick={gotoPrevStep}>
              <SVG src="/images/signup/icon-back.svg" />
              <div className="text-bold m-b-05">Connect with</div>
              <div className="text-dark text-big text-bolder">
                Heylink Script
              </div>
            </div>
            <div className="guide-content">
              <div className="m-b-6">
                Insert the Heylink script in the {`<head>`} tag of{' '}
                {host(channelDomain)}.
              </div>
              <pre className="m-b-4">{`<script src='${scriptHost}/${channelUuid}/script.js' defer></script>`}</pre>
              <div className="row row-top row-space-between">
                <button
                  className="btn btn-link btn-sm vertical-middle"
                  onClick={() => {
                    copyToClipboard(
                      `<script src='${scriptHost}/${channelUuid}/script.js' defer></script>`
                    )
                    showSnackbar('Copied to clipboard')
                  }}
                  tabIndex={tabIndex}
                >
                  <SVG src="/images/icon-copy.svg" className="m-r-15" />
                  Copy script
                </button>
                <button
                  className="link text-bold"
                  onClick={() =>
                    showModal(
                      <HelpModal
                        title="How to add the Heylink Script"
                        path="/outbound/add-heylink-outbound-script"
                      />,
                      { cardClassName: 'signup-help-modal-card' }
                    )
                  }
                  tabIndex={tabIndex}
                >
                  How do I add the script?
                </button>
              </div>
            </div>
          </div>
        )}

        {connectType === 'wordpress' && (
          <div className="signup-guide">
            <div className="guide-header" onClick={gotoPrevStep}>
              <SVG src="/images/signup/icon-back.svg" />
              <div className="text-bold m-b-05">Connect with</div>
              <div className="text-dark text-big text-bolder">
                Wordpress Plugin
              </div>
            </div>
            <div className="guide-content">
              <div className="m-b-4">Here is you Plugin API key:</div>
              <pre className="m-b-4">{channelUuid}</pre>
              <button
                className="btn btn-link btn-sm vertical-middle"
                onClick={() => {
                  copyToClipboard(channelUuid)
                  showSnackbar('Copied to clipboard')
                }}
                tabIndex={tabIndex}
              >
                <SVG src="/images/icon-copy.svg" className="m-r-15" />
                Copy Plugin API key
              </button>
              <div className="m-t-30 m-b-4">
                Download and install the Heylink Wordpress plugin, and then
                verify the connection.
              </div>
              <a
                href="https://plugin.heylink.com/latest.html"
                target="_blank"
                className="signup-download vertical-middle m-b-4"
                tabIndex={tabIndex}
              >
                <span>Download plugin</span>
                <SVG
                  src="/images/signup/icon-download.svg"
                  width={16}
                  className="m-l-4 m-y--2"
                />
              </a>
              <div className="row row-top row-space-between">
                <div />
                <button
                  className="link text-bold"
                  onClick={() =>
                    showModal(
                      <HelpModal
                        title="How to install the Wordpress plugin"
                        path="/outbound/install-heylink-outbound-wordpress-plugin"
                      />,
                      { cardClassName: 'signup-help-modal-card' }
                    )
                  }
                  tabIndex={tabIndex}
                >
                  How do I install and configure the Heylink WP plugin?
                </button>
              </div>
            </div>
          </div>
        )}

        {connectType === 'api' && (
          <div className="signup-guide">
            <div className="guide-header" onClick={gotoPrevStep}>
              <SVG src="/images/signup/icon-back.svg" />
              <div className="text-bold m-b-05">Connect with</div>
              <div className="text-dark text-big text-bolder">Custom API</div>
            </div>
            <div className="guide-content">
              <div className="m-b-5">
                Implement the API and make a test conversion. As soon as the
                test conversion is verified we will send you an email with
                access to your dashboard.
              </div>
              <div>
                <a
                  href="https://docs.heylink.com"
                  target="_blank"
                  className="signup-api"
                  onClick={submit}
                  tabIndex={tabIndex}
                >
                  <div>
                    <div className="vertical-middle text-nowrap">
                      <SVG src="/images/signup/icon-api.svg" />
                      <span className="m-l-4">
                        Custom API implementation guide
                      </span>
                    </div>
                    <SVG
                      src="/images/signup/icon-arrow.svg"
                      className="arrow"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        )}

        {(connectType === 'script' || connectType === 'wordpress') && (
          <>
            <div>
              <button
                className={varClass({
                  'signup-verify vertical-middle': true,
                  'submitting': submitting,
                })}
                onClick={submit}
                disabled={submitting}
                tabIndex={tabIndex}
              >
                {submitting ? (
                  <>
                    <span className="spinner" />
                    Verifying...
                  </>
                ) : (
                  <>Verify connection</>
                )}
              </button>
            </div>

            {error && (
              <div
                className="signup-verify-error text-center text-red text-bolder"
                dangerouslySetInnerHTML={{ __html: error }}
              />
            )}

            <button
              className="signup-skip m-t-10"
              onClick={() => {
                setStep(5)
              }}
              tabIndex={tabIndex}
            >
              Skip for now
            </button>
          </>
        )}
      </div>
    </div>
  )
}

const Step5 = (props) => {
  const { tabIndex, channelDomain, step, connectVerified } = props

  useEffect(() => {
    if (step === 5) {
      removeValue(signupStateStorageKey)
    }
  }, [step])

  return (
    <div className="step">
      <div className="step-content">
        <div className="signup-done-illustration m-l--6 m-b-40">
          <div className="signup-done-icon">
            {(channelDomain || 'x')[0].toUpperCase()}
          </div>
          <SVG src="/images/signup/icon-done.svg" />
        </div>
        {connectVerified && (
          <>
            <div className="h1 m-b-10">It works!</div>
            <div className="text-light text-bigger text-bold m-b-30">
              We have established a connection to{' '}
              <span className="text-primary">{host(channelDomain)}</span>.
            </div>
          </>
        )}
        <div className="text-dark text-largest text-bolder m-b-10">
          Your dashboard is ready 🎉
        </div>
        <div className="text-light text-bigger text-bold m-b-100">
          Get in there and connect your first network to get started!
        </div>
        <div>
          <a
            href="/"
            className="signup-verify block text-center text-undecorated"
            tabIndex={tabIndex}
          >
            Let's get started - Go to dashboard
          </a>
          {/*
        <a href="https://docs.heylink.com" className="signup-done">
          <div>
            <SVG src="/images/signup/icon-api.svg" />
            <span>
              If you are eager to get started, go have a look at our
              documentation.
            </span>
            <SVG src="/images/signup/icon-arrow.svg" className="arrow" />
          </div>
        </a>
        */}
        </div>
      </div>
    </div>
  )
}

const TermsModal = (props) => {
  return (
    <>
      <div className="card-body signup-terms-modal">
        <div className="text-dark text-larger text-boldest m-b-20">
          Heylink’s Terms and Conditions
        </div>
        <div className="terms-content">
          <div className="text-right text-light text-smaller text-bolder">
            Last updated: 16/9/2019
          </div>
          <b>1. Background</b>
          <p>
            1.1 Heylink ApS, a Danish company with its address at Bredgade 23B,
            4, 1260 Copenhagen, Denmark, duly incorporated under Danish Law at
            the Danish Business Authorities under Company Number (CVR-no.)
            40007431 (hereinafter “Heylink”).
          </p>
          <p>
            1.2 Heylink offers a service whereby Heylink monitors and tracks
            websites with editorial content (hereinafter a “Publisher”) so to
            that the Publisher may create a revenue stream from referrals to web
            shops (hereinafter “Merchants”) by earning a commission for
            referrals leading to a signup or a sale to the Merchants
            (hereinafter the “Service”).
          </p>
          <p>
            1.3 The Publisher operates one or more websites with editorial
            content, be it a news site or a site for a particular group of
            interest, like a hobby, a condition, or another common interest,
            where the Publisher can publish articles mentioning products or
            services and where readers of the articles can buy them/it.
          </p>
          <p>
            1.4 The Services provides articles mentioning products or services
            with links to Merchants who are participating in the advertising
            affiliate networks that Heylink cooperates with. The Services allows
            for tracking referrals if and when a user use a link in an article
            on the Publishers website to go to the Merchants in order to buy or
            subscribe for a product or service mentioned in an article.
          </p>
          <b>2. The Service</b>
          <p>
            2.1 The particular service within the Service to be provided by
            Heylink is described in detail in an offer or in an order
            confirmation to which are attached to these Terms of Service
            (hereinafter “Terms”).
          </p>
          <p>
            2.1.1 Heylink will provide the Publisher with a script to be placed
            on the Publishers website which allows tracking of users that
            activate links on the website. The script will be a Heylink script
            and Heylinks description of the script may be found here, so the
            Publisher can use it in their cookie declaration.
          </p>
          <p>
            2.1.2 Furthermore, Heylink will provide the Publisher with unique
            links for the user to activate to Merchants that either Heylink has
            a direct referral agreement with or who the affiliate network that
            Heylink is cooperating with has a referral agreement with.
          </p>
          <p>
            2.1.2.1 Publisher understand that Heylink cannot guarantee that
            Heylink will be able to provide links to a particular Merchants but
            Heylink will use reasonable efforts to link to particular Merchants
            for instance in case of a review of a product or service with a
            particular Merchants.
          </p>
          <p>
            2.1.3 The Service will also include access for the Publisher to a
            dash board, where the Publisher may monitor the performance of its
            websites, the different articles, number of links, conversions etc.
            Heylink will provide the Publisher with confidential login
            information to be used solely by the Publisher and for internal use
            only.
          </p>
          <p>
            2.2 Heylink will constantly seek to work on improving the
            documentation for the conversion being due to the referral from the
            Publisher.
          </p>
          <b>3. The Publishers responsibilities</b>
          <p>3.1 The Publisher may not undertake the following activities:</p>
          <p>
            3.1.1 Divert traffic to its own site in competition with a Merchant
            including but not limited to using search engines such as Google to
            bid on keywords in any way related to a Merchants-brand.
          </p>
          <p>
            3.1.2 Use the articles with the links in e-mail marketing campaigns
            unless expressly agreed with Heylink.
          </p>
          <p>
            3.2 The Publisher shall ensure that content on its website is not
            and cannot be seen as being of a pornographic, violent or
            discriminating in nature, contains obvious fake news, infringe third
            party rights, violates fundamental principles of law including but
            not limited to violating of third-party rights.
          </p>
          <p>
            3.3 When operating web sites where the main audience are children
            (defined as a person under the age of 18) the Publisher shall ensure
            that not only the articles, but also the Merchants, are in full
            compliance with the applicable regulations on sale and marketing of
            products and services to children.
          </p>
          <p>
            3.4 Publisher shall always comply with the Merchants guidelines on
            referrals.
          </p>
          <p>
            3.5 Publisher is responsible for abiding by the applicable
            regulation on the use of tracking technology on its websites.
          </p>
          <b>4. Heylink consideration and pay-out of revenue</b>
          <p>
            4.1 The consideration to Heylink for providing the Service is a
            share of the revenue obtained through the referrals.
          </p>
          <p>
            4.2 Unless otherwise agreed al referrals shall be calculated and
            paid out in DKK.
          </p>
          <p>
            4.3 Pay-out of revenue to the Publisher takes place on the 10. April
            10, July, 10 October, 10 January based on the calculation of the
            revenue earned in the preceding quarter provided, however the
            following conditions are met:
          </p>
          <p>
            4.3.1 The revenue to be paid out exceeds DKK 1,000/EURO 150
            depending on the agreed currency.
          </p>
          <p>
            4.3.2 That Heylink has received payment from the affiliate
            networks/Merchants involved in the referrals.
          </p>
          <p>
            4.4 Heylink shall be entitled to add appropriate VAT to any part of
            the pay-out including but not limited to its consideration for the
            Service.
          </p>
          <p>
            4.5 If the Publisher has not complied with the Merchants guidelines
            and the Merchants therefore denies to pay-out a commission, Heylink
            shall have the right to request the Publisher to pay its
            consideration and to refer the Publisher to pursue the claim for
            commission for the Merchants itself.
          </p>
          <p>
            4.6 Heylink shall be entitled with binding effect for the Publisher
            to settle any disputes with an affiliate network or a Merchants on
            whether a referral from the Publisher entitles the Publisher to a
            provided commission, however that Heylink has used responsible
            effort to convince the affiliate network or the Merchant that the
            Publisher is entitled to a commission.
          </p>
          <b>5. Providing content</b>
          <p>
            5.1 If agreed with Publisher, Heylink will produce draft articles
            (hereinafter “Content”) and/or draft articles which are paid for by
            a Merchant or a product- or service owner (hereinafter “Product
            Placement”).
          </p>
          <p>
            5.2 If and when Heylink produce draft Content and/or Product
            Placement, Heylink will always observe basic principles for
            journalism and make sure that the articles are a “free piece” of
            journalism but at the same time is written in such a way that it
            urges the user to click on the links.
          </p>
          <p>
            5.3 If Heylink is producing a draft to a Product Placement article
            it is the sole responsibility of the Publisher to label the article
            correctly as an advertisement/commercial content.
          </p>
          <p>
            5.4 Heylink will constantly work on improving the ability for an
            article to urge the user to link to a Merchant.
          </p>
          <p>
            5.5 Unless otherwise agreed, agreements about content provided by
            Heylink, can be terminated by a party with a six-month written
            notice. If the parties have agreed on thresholds to be meet, e.g.
            number of readers per article, number of referrals, number of unique
            users on the Publishers website etc. the terms thereof will be
            described in detail in a separate e-mail . Noncompliance with the
            agreed thresholds shall allow Heylink to terminate the agreement on
            drafting articles with a notice of one (1) month.
          </p>
          <b>6. Heylinks liabilities and responsibilities</b>
          <p>6.1 Heylink cannot be held liable for:</p>
          <p>6.1.1 The articles’ ability to create referrals,</p>
          <p>6.1.2 Any broken links,</p>
          <p>
            6.1.3 Publisher uses third-party scripts on the websites, causing a
            conflict with a Heylink script, whereby either the Heylink script or
            a third-party script malfunctions, so that referrals may not be
            reported or may not be reported correctly.
          </p>
          <p>
            6.1.4 Missing declaration of third-party cookies used by Merchant or
            affiliate networks due to lack of information from the
            Merchant/affiliate network.
          </p>
          <p>
            6.1.5 Any failure to monitor a conversion of a referral due to
            circumstances outside the control of Heylink,
          </p>
          <p>6.1.6 Lacking payment from Merchants or and affiliate network</p>
          <p>
            6.2 Heylink shall hold the Publisher harmless and indemnify the
            Publisher against any claims based on a substantiated allegation
            that an article produced by Heylink infringes a third party’s
            copyright and therefore is not a free creation by Heylinks employees
            or freelancers.
          </p>
          <p>
            6.3 Heylink shall not be held responsible for any violation of any
            marketing regulation unless Heylink expressly has advised on an
            article’s compliance with the applicable marketing practice act.
          </p>
          <p>
            6.4 Save for the liability under clause 6.2, Heylink’s total
            liability under these Terms is maximized to an amount of DKK 100,000
            for the total duration of the period where Heylink has provided the
            Service to the Publisher.
          </p>
          <b>7. Processing of personal data</b>
          <p>
            7.1 Heylink does not collect or otherwise process personal data as
            defined in art. 4 of Regulation (EU) 2016/679 of the European
            Parliament and of the council of 27 April 2016 on the protection of
            natural persons with regard to the processing of personal data and
            on the free movement of such data, and repealing Directive 95/46/EC
            ,when performing the Service and therefore Heylink is depended on
            the ability of the affiliate networks and/or the Merchants to track
            and report back the conversion as Heylink’s script only monitors the
            traffic from the Publishers web site(s).
          </p>
          <b>8. Term and termination</b>
          <p>
            8.1 The Publisher can terminate the use of the Service at any time,
            upon a written notice to Heylink, except for content agreements as
            described in clause 5. by deleting the Heylink script.
          </p>
          <p>
            8.2 Heylink may terminate the Publishers use of the Service with a
            six-month written notice.
          </p>
          <p>
            8.3 In case of a material breach the party not in breach, may
            terminate the use of the Service without a notice provided however
            that the party in breach, has not remedied the material breach after
            having received a written notice from the party not in breach, of
            the material breach setting a remedy period of a minimum of ten
            working days and clearly stating the use of the Service will be
            terminated if the breach has not been remedied within the stated
            remedy period.
          </p>
          <b>9. Choice of law and venue</b>
          <p>
            9.1 These Terms and any disputes in connection with the delivery of
            the Service shall be governed by and construed in accordance with
            the substantive laws of Denmark (excl. CISG and renvoi).
          </p>
          <p>
            9.2 Any dispute or claim arising out of or in connection with the
            delivery of the Service or the breach, termination or invalidity of
            the Terms shall be settled by arbitration in accordance with the
            “Rules of Procedure of the Danish Institute of Arbitration (Danish
            Arbitration)”. The tribunal shall be composed by three arbitrators
            appointed by the Institute. In case the Publisher is subject to any
            insolvency proceeding or suspends its payments, or if the monetary
            value of the dispute or claim does not exceed an amount equal to
            EURO 100,000, then arbitral proceeding shall be conducted as a
            simplified arbitration procedure. The chairman or the single
            arbitrator must be domiciled in Denmark. The language of the
            arbitration shall be Danish unless one of the parties’ request that
            the arbitration shall be conducted in English.
          </p>
          <p>
            9.3 The place of arbitration shall be Copenhagen, Denmark. The
            proceedings and awards shall, save as may be required for defence
            and enforcement, be confidential.
          </p>
          <p>
            9.4 If the dispute involves claims that Heylink has to direct to a
            third party with whom Heylinks does have an arbitration clause,
            Heylink may demand that the dispute is settled before the ordinary
            courts in Denmark together with the dispute with the third party
            against whom Heylink as a claim for payment or compensation directly
            linked to the claim from the Publisher.
          </p>
        </div>
      </div>
    </>
  )
}

const HelpModal = (props) => {
  const { title, path } = props

  return (
    <>
      <div className="card-body signup-help-modal">
        <div className="text-dark text-larger text-boldest m-b-20">{title}</div>
        <div className="help-content">
          <iframe src={`https://docs.heylink.com${path}?embed`} />
        </div>
      </div>
    </>
  )
}
